html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    /*font-size: 100%;*/
    /*font: inherit;*/
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

footer {
    margin-top: 5px;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after {
    content: '';
    content: none;
}

q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    /*font-family: Roboto;*/
    box-sizing: border-box;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: NotoSansKRRegular sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}


html,
body,
#b2b {
    min-height: 100%;
    min-width: 1900px;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    justify-content: center;
    height: 100%;
}


html, body {
    background-color: #ffffff !important;
}

/*login*/

.overlay section.user article button {
    border: none;
    cursor: pointer;
    color: white;
}

.overlay {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*background-color: #ffffff;*/
}

.overlay section.user {
    /*display: flex;*/
    /*position: absolute;*/
    /*transform: translate(-50%, -50%);*/
    /*left: 50%;*/
    /*top: 40%;*/
    /*padding: 0;*/
    margin-top: 100px;
}

.overlay section.user article {
    flex: 1;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    height: auto;
    /* min-height: 8s00px; */
    /*width: 600px;*/
    flex-flow: column;
    position: relative;
    padding-bottom: 0;
}

.overlay section.user article.main {
    gap: 10px;
}

.overlay .login img {
    width: 540px;
    margin-top: 20px;
}

.overlay .login p {
    font-size: 20px;
    line-height: 22px;
    margin-top: 40px;
}

.register-btn-wrap {
    margin-top: 20px;
    position: relative;
    text-align: center;
}

.register-btn {
    width: 200px;
    height: 44px;
    color: #ffffff;
    background-color: #1976d2;
    font-size: 16px;
    border-radius: 3px;
    border: 0;
}

.overlay section.user article.main .register-form {
    width: 320px;
    /*margin-top: 20px;*/
    text-align: center;
}

.overlay section.user article.main .register-form input {
    width: 350px;
    height: 40px;
    padding-left: 5px;
    border: 1px solid black;
}

.overlay section.user article.main .register-form button {
    width: 100%;
    height: 44px;
    line-height: 38px;
    background-color: #003f7b;
    font-size: 16px;
    margin-top: 10px;
    border-radius: 3px;
    font-weight: normal;
    color: #ffffff;
    font-family: NotoSansKRMedium sans-serif;
}

.forgot-button-wrap {
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
    margin-top: 40px;
}

.forgot-pw {
    color: #1976d2 !important;
    background-color: #ffffff !important;
    text-align: left;
}

.footer-wrap {
    display: flex;
    width: 800px;
}

.overlay section.user article .main-footer {
    width: 100%;
    /*position: absolute;*/
    bottom: 100px;
    justify-content: center;
    font-size: 14px;
    /*transform: translateX(-50%);*/
    left: 50%;
    text-align: center;
    margin-top: 100px;
}

.overlay section.user article .main-footer:nth-child(3) {
    margin-top: 40px;
}

.overlay section.user article .main-footer img {
    height: 30px;
    margin-top: 10px;
}

.overlay section.user article.login .form-wrapper {
    width: 320px;
    margin-top: 40px;
}

.overlay section.user article.login .form-wrapper .text-field {
    margin-top: 10px;
    display: block;
    height: auto;
    flex-flow: column;
    gap: 5px;
}

.login .form-wrapper .text-field label {
    color: #323232;
    font-size: 14px;
}

.login .form-wrapper .text-field input {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    padding: 0 8px;
    /*border: none;*/
    /*border-bottom: 1px solid #d0d0d0;*/
}

.login .form-wrapper .checkbox {
    margin-top: 5px;
}

.login .form-wrapper .checkbox input {
    margin-right: 10px;
}

.login .form-wrapper .checkbox label {
    color: #7d7d7d;
    font-size: 16px;
    margin-top: 4px;
    /*margin-left: 4px;*/
}

.login .form-wrapper .button-wrapper {
    /*margin-top: 30px;*/
    text-align: center;
}

.login .form-wrapper .button-wrapper button.login {
    border-radius: 3px;
    background-color: #1976d2;
    font-size: 16px;
    width: 100%;
    height: 44px;
    line-height: 38px;
    font-family: NotoSansKRMedium sans-serif;
}

.overlay section.user article .login-footer img:nth-child(2) {
    height: 25px;
}

.overlay section.user article .login-footer img:nth-child(3) {
    height: 22px;
    margin-left: 20px;
    margin-bottom: 3px;
}

/*join*/
article.join .form-wrapper form .text-field-wrapper {
    display: flex;
    /*height: 500px;*/
    flex-flow: column;
    /*flex-wrap: wrap;*/
    margin: 10px 0;
}


.join .form-wrapper {
    width: 340px;
    /*min-height: auto;*/
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
    text-align: center;
}

.join .form-wrapper img {
    width: 300px;
    margin-bottom: 20px;
}

article.join .form-wrapper form .text-field-wrapper .text-field {
    width: 300px;
    /*border-bottom: 2px solid #eee;*/
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.text-field.top, .text-field.bottom {
    flex-flow: column;
    gap: 5px;
}

.text-field label {
    /*flex: 1;*/
    display: flex;
    align-items: center;
}

.join label {
    font-size: 14px;
    font-family: NotoSansKRMedium sans-serif;
}

.text-field {
    display: flex;
    /* display: none; */
    /*height: 30px;*/
}

article.join .form-wrapper form .text-field-wrapper .text-field input {
    /*height: 24px;*/
    border: 0.5px solid #9d9d9d;
    padding: 4px 8px;
}

.register-form .text-field {
    height: 40px;
}

.text-field input {
    flex: 2;
    /* border: none; */
    height: 100%;
}

article.join .form-wrapper form .text-field-wrapper .text-field span {
    cursor: pointer;
    /*position: absolute;*/
    /*bottom: -40px;*/
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: #128ec7;
    color: white;
    /* font-weight: bold; */
    font-size: 20px;
    text-align: center;
}

article.join .form-wrapper form .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
}

article.join .form-wrapper form .button-wrapper button {
    padding: 9px 43px 8px;
    width: 160px;
    font-weight: 500;
    height: 45px;
    border-radius: 30px;
    font-size: 16px;
    color: #ffffff;
    border: none;
}


article.join .form-wrapper form .button-wrapper button.grey {
    background-color: #b0b0b0 !important;
}

/*sidebar*/
.sidebar {
    width: 300px;
    background-color: #191919;
    font-size: 14px;
}

.sidebar .logo {
    /*padding-top: 20px;*/
    text-align: center;
    height: 130px;
    position: relative;
}

.sidebar .logo img {
    width: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.appbar .user-info,
.appbar .message,
.appbar .user-account,
.appbar .user-group {
    /*width: 240px;*/
    /*height: 50px;*/
    /*background-color: #3a3a3a;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    /*border-radius: 3px;*/
    display: flex;
    /*padding: 0 15px;*/
    align-items: center;
    cursor: pointer;
    color: #b9b9b9;
    font-size: 14px;
    margin: 0 20px;
}

.appbar .user-info-true,
.appbar .message,
.appbar .user-account-true,
.appbar .user-group-true {
    /*width: 240px;*/
    /*height: 50px;*/
    /*background-color: #3a3a3a;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    /*border-radius: 3px;*/
    display: flex;
    /*padding: 0 15px;*/
    align-items: center;
    cursor: pointer;
    color: #4e9700;
    font-size: 14px;
    margin: 0 20px;
}

.appbar .user-info-false,
.appbar .message,
.appbar .user-account-false,
.appbar .user-group-false {
    /*width: 240px;*/
    /*height: 50px;*/
    /*background-color: #3a3a3a;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    /*border-radius: 3px;*/
    display: flex;
    /*padding: 0 15px;*/
    align-items: center;
    cursor: pointer;
    color: #b9b9b9;
    font-size: 14px;
    margin: 0 20px;
}

.user-info {
    /*margin-top: 40px;*/
    /*margin-bottom: 5px;*/
    /*margin-right: 40px;*/
}

.user-info .arrow-right,
.message .arrow-right {
    width: 8px;
}

.user-info span,
.message span,
.user-account span,
.user-group span {
    /*margin-left: 8px;*/
    flex: 1;
    font-family: NotoSansKRMedium sans-serif;
}

.sidebar .order-type {
    /*margin-top: 40px;*/
    width: 240px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-color: #3a3a3a;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}

.sidebar button {
    width: 110px;
    height: 40px;
    margin: 0 5px;
    color: #b9b9b9;
    background-color: #3a3a3a;
    border: none;
    font-size: 15px;
    /*font-weight: bold;*/
    font-family: NotoSansKRBold sans-serif;
}

.sidebar button:nth-child(1).selected {
    background-color: #191919;
    border-radius: 3px;
    color: #118ec7;
}

.sidebar button:nth-child(2).selected {
    background-color: #191919;
    border-radius: 3px;
    color: #ff4b4b;
}

.sidebar nav {
    margin-top: 20px;
}

.sidebar nav ul hr {
    width: 240px;
    border: 0.1px solid #4d4d4d;
}

.sidebar nav ul > div {
    /*width: 240px;*/
    /*line-height: 50px;*/
    /*height: 50px;*/
    margin-left: auto;
    margin-right: auto;
    /*padding-left: 10px;*/
    cursor: pointer;
    position: relative;
}

/*.sidebar nav ul li.single:hover{*/
/*  background-color: #272727;*/
/*}*/

.sidebar nav ul div a {
    text-decoration: none;
    color: #b9b9b9;
}

.sidebar nav ul div a li{
    font-size: 15px!important;
}

.sidebar nav ul div a .new-count {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.sidebar nav ul div a.active .expand-more {
    display: none;
}

.sidebar nav ul div a.active .expand-less {
    display: block;
}

.sidebar a img {
    width: 12px;
    margin-left: 10px;
}

.sidebar .active a img {
    background-image: url(https://directional.net/images/up.png);
}

.sidebar nav.LEND ul li.single .active {
    color: #118ec7;
}

.sidebar nav.LEND ul li ul li .active {
    color: #118ec7;
}

.sidebar nav.BORROW ul li.single .active {
    color: #ff4b4b;
}

.sidebar nav.BORROW ul li ul li .active {
    color: #ff4b4b;
}

/*sidebar end*/

/*section*/
/* .container > div:nth-child(3) {
    flex: 1;
    padding: 10px;
} */

header {
    width: 100%;
    height: 45px;
    line-height: 45px;
    position: relative;
    background-color: white;
    margin-bottom: 10px;
    font-size: 16px;
    /*font-weight: bold;*/
    color: #3a3a3a;
    padding-left: 15px;
    font-family: NotoSansKRMedium sans-serif;
}

.logout {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    /*border: 1px solid #c6c6c6;*/
    background-color: #323232;
    width: 100px;
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
    text-align: center;
    color: #b9b9b9;
    font-size: 14px;
    /*font-weight: bold;*/
    cursor: pointer;
    font-family: NotoSansKRMedium sans-serif;
}

.logout:hover {
    color: #18629e;
}

.language-wrap {
    position: absolute;
    top: 50%;
    right: 200px;
    transform: translateY(-50%);
    /*border: 1px solid #c6c6c6;*/
    background-color: #323232;
    width: 100px;
    height: 34px;
    line-height: 34px;
    display: flex;
    /*background-color: #323232;*/
    border-radius: 3px;
    text-align: center;
    color: #b9b9b9;
    font-size: 14px;
    cursor: pointer;
    font-family: NotoSansKRMedium sans-serif;
}

.language {
    width: 50px;
    height: 34px;
    line-height: 34px;
}

.language-selected {
    width: 50px;
    height: 34px;
    line-height: 34px;
    background-color: #04a1d5;
}

/*tab*/
.container article {
    background-color: #ffffff;
    padding-bottom: 20px;
    overflow: auto;
    width: 100%;
    height: 900px;
}

.container article.join {
    height: unset;
}

.tab {
    width: 100%;
    height: 80px;
    background-color: #f5f5f5;
    padding: 0 30px;
}

.tab ul {
    display: flex;
    gap: 2px;
    padding-top: 40px;
}

.tab ul li {
    /*width: 140px;*/
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    background-color: #ebebeb;
    font-size: 15px;
}

.tab ul li.selected {
    background-color: #00245f;
    color: #ffffff;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

.message-list .tab ul li.selected {
    background-color: #3a3a3a;
}

.tab.borrow ul li.selected {
    background-color: #9e0000;
    color: #ffffff;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

.BORROW .tab ul li.selected {
    background-color: #9e0000;
    color: #ffffff;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

/*article*/
.button-wrap {
    padding: 0 30px;
    text-align: right;
    margin-top: 20px;
    position: relative;
}

.all-cancel-btn {
    width: 160px;
    height: 40px;
    line-height: 40px;
    background-color: #fa4e60;
    margin-left: 30px;
    margin-top: 20px;
    text-align: center;
    border-radius: 3px;
    color: #ffffff;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

.ytw-btn {
    width: 160px;
    height: 40px;
    line-height: 40px;
    background-color: #18629e;
    margin-right: 30px;
    margin-top: 20px;
    text-align: center;
    border-radius: 3px;
    color: #ffffff;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
    float: right;
}

.total {
    text-align: left;
    position: absolute;
    top: 0;
}

.total ul li {
    font-size: 14px;
    margin-bottom: 5px;
}

.total .search-issue,
.confirm-list .search-issue {
    margin-top: 10px;
    width: 320px;
}

.confirm-list .search-issue {
    padding-left: 30px;
}

.confirm-list .notice {
    padding-left: 30px;
    margin-top: 30px;
    font-size: 14px;
}

.total .search-issue .text-field,
.confirm-list .search-issue .text-field {
    width: 300px;
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
}

.total .search-issue .text-field input,
.confirm-list .search-issue .text-field input {
    background-color: #ffffff;
    border: 1px solid #121212;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 14px;
    height: 30px;
    flex: 2;
    align-items: center;
}

.total .search-issue .search-subtext,
.confirm-list .search-issue .search-subtext {
    font-size: 12px;
    margin-top: 5px;
    color: #9d9d9d;
    padding-left: 5px;
}

.total .search-issue .text-field img,
.confirm-list .search-issue .text-field img {
    position: absolute;
    right: 10px;
    width: 20px;
}


article .button-wrap button {
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: none;
    border-radius: 2px;
    color: #ffffff;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
    font-size: 14px;
    margin: 0 4px;
    cursor: pointer;
}

article button:nth-child(1):not([class^="Mui"]) {
    background-color: #fa4e60;
}

article button:nth-child(2):not([class^="Mui"]) {
    background-color: #18629e;
}

.borrow-pool-list article button:nth-child(1),
.confirm-list article button:nth-child(1),
.message-send article button:nth-child(1),
.message-list article .button-wrap > button:nth-child(1) {
    background-color: #18629e;
}

.lend-order-response article button:nth-child(2),
.borrow-order-response article button:nth-child(2) {
    background-color: #f7ae5f;
}

.lend-order-response article button:nth-child(3),
.borrow-order-response article button:nth-child(3) {
    background-color: #18629e;
}

article .option {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 2px;
    border: solid 1px #c2c2c2;
    background-color: #fff;
    padding: 25px 20px;
}

.borrow-pool-list article .option {
    margin-top: 20px;
}

article .option .first-line {
    display: flex;
    margin-bottom: 30px;
    position: relative;
}

article .option .first-line div:nth-child(1) {
    margin-right: 105px;
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
}

article .option .first-line h3 {
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
    font-size: 15px;
}

article .option .first-line span:not([class^="Mui"]) {
    font-size: 14px;
    /* text-decoration: underline; */
    color: #1976d2;
    /* font-weight: bold; */
    margin-left: 10px;
    line-height: 20px;
    cursor: pointer;
    font-family: NotoSansKRMedium sans-serif;
    display: flex;
    align-items: center;
    border: 1px solid #1976d2;
    /* height: 20px; */
    padding: 2px 10px;
    border-radius: 3px;
}

article .option .first-line span:not([class^="Mui"]):hover {
    background-color: rgba(25, 118, 210, 0.05);
}

article .option .first-line span > svg {
    font-size: 1.0rem;
}

article .option .second-line {
    display: flex;
}

article .option .second-line .checkbox-wrap span {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    display: inline-block;
    font-family: NotoSansKRMedium sans-serif;
}


article .option .second-line div label {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
    font-family: NotoSansKRMedium sans-serif;
}

article .option .second-line .text-field,
article .option .second-line .select-option {
    margin-right: 50px;
}

/*.lend-order-list article .option .second-line .text-field,*/
.search-list article .option .second-line .text-field {
    /*.borrow-order-list article .option .second-line .text-field{*/
    margin: 0;
}

.lend-order-list article .option .second-line span,
.search-list article .option .second-line span,
.borrow-order-list article .option .second-line span,
.lend-search article .option .second-line span,
.borrow-search article .option .second-line span,
.borrow-pool-list article .option .second-line span.date-dash,
.lend-borrow-list article .option .second-line span.date-dash {
    line-height: 30px;
}

/*.lend-order-list article .option .second-line > span:nth-child(4),*/
.search-list article .option .second-line > span:nth-child(4)
    /*.borrow-order-list article .option .second-line > span:nth-child(4)*/
{
    margin-left: 15px;
}

.lend-order-list article .option .second-line span.date-dash,
.search-list article .option .second-line span.date-dash,
.borrow-order-list article .option .second-line span.date-dash,
.lend-search article .option .second-line span.date-dash,
.borrow-search article .option .second-line span.date-dash,
.borrow-pool-list article .option .second-line span.date-dash,
.lend-borrow-list article .option .second-line span.date-dash {
    padding: 0 5px;
}

article .option .second-line .text-field input {
    height: 30px;
    width: 240px;
}

article .option .second-line .select-option select {
    width: 120px;
    height: 30px;
}

article .option .second-line button:not([class^="Mui"]) {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #118ec7;
    color: #ffffff;
    /*font-weight: bold;*/
    margin-left: auto;
    border: none;
    border-radius: 3px;
    font-family: NotoSansKRMedium sans-serif;
}

article .option .third-line {
    border-top: 1px solid #dadada;
    margin-top: 20px;
    padding-top: 20px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
}

article .option .third-line .li-company {
    list-style: none;
    margin-right: 20px;
    gap: 10px;
}

.li-group {
    line-height: 18px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 8px;
    margin-bottom: 10px;
    cursor: pointer;
}

.li-group-list {
    line-height: 18px;
    font-size: 14px;
    padding: 0 8px;
    margin-bottom: 10px;
    cursor: pointer;
}

.li-group-list input {
    margin-right: 5px;
}

.lend-order-list article .option .third-line,
.search-list article .option .third-line,
.borrow-order-list article .option .third-line {
    border: 0;
    display: flex;
}

.lend-order-list article .option .third-line div,
.search-list article .option .third-line div,
.borrow-order-list article .option .third-line div {
    margin-right: 50px;
}

.lend-order-list article .option .third-line .select-option select,
.search-list article .option .third-line .select-option select,
.borrow-order-list article .option .third-line .select-option select {
    width: 120px;
    height: 30px;
}

.lend-order-list article .option .third-line div label,
.search-list article .option .third-line div label,
.borrow-order-list article .option .third-line div label {
    margin-right: 10px;
    font-size: 14px;
}

.lend-order-list article .option .third-line div input,
.search-list article .option .third-line div input,
.borrow-order-list article .option .third-line div input {
    height: 30px;
}

article .option .third-line ul {
    display: flex;
    margin-top: 10px;
}

article .option .third-line ul li {
    margin-right: 25px;
}

article .option .third-line ul li span {
    color: #fa4e60;
    margin-left: 5px;
    cursor: pointer;
}

article > span {
    margin-left: 30px;
    margin-top: 25px;
    color: #118ec7;
    text-decoration: underline;
    font-size: 14px;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}


.option .checkbox-wrap {
    /*width: 200px;*/
    margin-right: 50px;
}

.option .checkbox-wrap .checkbox {
    line-height: 20px;
    display: flex;
    height: 20px;
    align-items: center;
}

.option .checkbox-wrap .checkbox input {
    margin: 0;
    width: 14px;
    height: 14px;
}

.option .checkbox-wrap > div:nth-child(2) {
    margin-top: 5px;
}

.autocomplete-wrap > div:nth-child(1) {
    width: 240px;
}

.autocomplete-wrap > div:nth-child(1) > div {
    padding: 0 !important;
    position: relative;
    height: 40px;
}

.autocomplete-wrap > div:nth-child(1) > div > input {
    /*padding: 0 !important;*/
    height: 30px;
}

.autocomplete-wrap > div:nth-child(1) > div > div {
    transform: translateY(-50%);
    top: 50%;
    right: 0 !important;
}

.autocomplete-wrap > div:nth-child(2) {
    padding: 0 !important;
}

.autocomplete-wrap > div:nth-child(2) > button {
    padding: 0;
}

.autocomplete-wrap > div:nth-child(2) > button > svg {
    margin: 0;
    padding: 0;
    font-size: 1.0rem;
}

.autocomplete-wrap input {
    padding: 0;
    font-size: 14px;
}

.autocomplete-wrap fieldset {
    height: 40px;
    padding: 0;
    /*top: 0;*/
}

.option .checkbox label {
    padding-left: 8px;
    font-size: 14px;
    font-family: NotoSansKRMedium sans-serif;
}

.option ul li {
    display: flex;
    margin-right: 20px;
}

/*table*/

.table-bottom {
    margin-bottom: 1px;
}

.fixed-table {
    margin: 25px 30px;
    border: 1px solid #c2c2c2;
    height: 45%;
    overflow-y: auto;
}

.fixed-table-50 {
    margin: 25px 30px;
    border: 0.5px solid #d3d3d3;
    height: 50%;
    overflow-y: auto;
}

.fixed-table-40 {
    margin: 25px 30px;
    border: 0.5px solid #d3d3d3;
    height: 50%;
    overflow-y: auto;
}

.confirm-list .fixed-table,
.message-send .fixed-table,
.message-detail .fixed-table {
    margin-top: 5px;
}

.fixed-table table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    background-color: #ffffff;
    color: #b3b3b3;
}

.fixed-table table th {
    position: sticky;
    top: 0;
    /*border-left: 0.5px solid #d8d8d8;*/
    border-right: 0.5px solid #d8d8d8;
    background-color: #00245f;
    /* z-index: 100; */
    color: #ffffff;
    vertical-align: middle;
    padding: 0 8px;
    font-size: 13px;
    /* font-weight: bold; */
    text-align: left;
    font-family: NotoSansKRMedium sans-serif;
    /*height: 28px;*/
    /*line-height: 28px;*/
}

.fixed-table table th label {
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

.BORROW .fixed-table table th {
    background-color: #9e0000;
}

.fixed-table table td {
    height: 25px;
    line-height: 25px;
    border: 0.5px solid #d8d8d8;
    color: #000000;
    font-size: 13px;
    padding: 0 8px;
    /*text-align: center;*/
    vertical-align: middle;
}

.fixed-table table td.left {
    text-align: left;
}

.fixed-table table td select {
    font-size: 13px;
}

.fixed-table table td.right {
    text-align: right;
}

.fixed-table table .text-field {
    display: flex;
}

.fixed-table table .text-field input {
    width: 100%;
    box-shadow: inset 0 1px 3px 0 rgb(171 171 171 / 50%);
    border: none;
    flex: 2;
}

.mypage article {
    /*padding-bottom: 300px;*/
    height: unset;
    min-height: 900px;
}

.mypage article .user-info {
    display: flex;
    gap: 50px;
    margin-top: 60px;
    margin-left: 30px;
}

.mypage article .user-info .account-info {
    display: flex;
    flex-flow: column;
    margin-left: 15px;
}

.mypage article .user-info .account-info .text-field {
    width: 250px;
    height: auto;
    /*border-bottom: 2px solid #eee;*/
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.mypage article .user-info .account-info:nth-child(2) > .text-field {
    /*margin-bottom: 50px;*/
}

.mypage article .user-info .account-info .text-field label {
    /*font-weight: bold;*/
    flex: 1;
    font-family: NotoSansKRMedium sans-serif;
}

.mypage article .user-info .account-info .text-field input {
    height: 70%;
    /*margin-top: 5px;*/
    border: none;
    /*flex: 2;*/
    font-size: 14px;
    border-bottom: 2px solid #eee;
}

.mypage article .user-info .account-info .text-field span {
    cursor: pointer;
    /*position: absolute;*/
    text-decoration: underline;
    /*font-weight: bold;*/
    font-size: 12px;
    font-family: NotoSansKRMedium sans-serif;
}

.mypage article .user-info .account-info div.set-pw {
    color: #118ec7;
    /* margin-bottom: 20px; */
    /* text-decoration: underline; */
    cursor: pointer;
    border: 0.5px solid #118ec7;
    width: 140px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    font-family: NotoSansKRMedium sans-serif;

}

.mypage article .user-info .account-info .text-field span.add {
    left: 0;
    bottom: -24px;
    color: #262626;
}

.mypage article .user-info .account-info .text-field span.del {
    right: 0;
    color: #f94e5f;
}

.mypage article .ksd-account-info {
    margin-top: 60px;
    /*margin-left: 30px;*/
    /*padding-right: 30px;*/
}

.mypage article .ksd-account-info h3,
.message-send article h3,
.message-detail article h3 {
    font-size: 16px;
    /*font-weight: bold;*/
    margin-bottom: 10px;
    color: #323232;
    margin-left: 30px;
    font-family: NotoSansKRMedium sans-serif;
}

.mypage article .ksd-account-info .search-mypage {
    width: 400px;
    margin-bottom: 15px;
    padding: 0 30px;
}

.message-send article h3,
.message-detail article h3 {
    margin-top: 30px;
    /*padding-left: 30px;*/
}

.ksd-account-info table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f6f6f6;
    color: #b3b3b3;
    /*height: 34px;*/
    /*line-height: 34px;*/
    text-align: center;
    /*margin-bottom: 10px;*/
    /* border: 1px solid black; */
}

.ksd-account-info table th {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    /*z-index: 9999;*/
    font-size: 14px;
    color: #000000;
    /*text-align: center;*/
    font-family: NotoSansKRMedium sans-serif;
}

.ksd-account-info table tr {
    /*height: 34px;*/
    /*line-height: 34px;*/
    background-color: #ffffff;
    color: #ffffff;
    font-size: 14px;
}

.ksd-account-info table td {
    border: 1px solid #d8d8d8;
    color: #000000;
    /*line-height: 34px;*/
    text-align: left;
}

.mypage article .ksd-account-info table td .text-field {
    margin: 0 auto;
    /*width: 90%;*/
    height: 25px;
    display: flex;
}

.mypage article .ksd-account-info table td .text-field input {
    box-shadow: inset 0 1px 3px 0 rgb(171 171 171 / 50%);
    flex: 2;
    border: none;
    height: 19px;
    width: 100%;
    padding: 0 8px;
    /*text-align: center;*/
    margin-top: 3px;
}

.mypage article .ksd-account-info p {
    font-size: 14px;
    margin-left: 30px;
    color: #FA4E58;
}

.mypage article .container section article .fixed-table table .checkbox {
    justify-content: center;
    align-items: center;
    display: flex;
}

.mypage article .ksd-account-info table td span.add {
    color: #000000;
}

.mypage article .ksd-account-info table td span.del {
    color: #FA4E58;
}

.mypage article .ksd-account-info .selected-cp-list {
    background-color: #ffffff;
    border: 1px solid #121212;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 14px;
    width: 300px;
    flex: 1;
    position: relative;
    display: flex;
    height: 30px;
    margin-bottom: 10px;
}

.mypage article .ksd-account-info table td span {
    text-decoration: underline;
    cursor: pointer;
}

.mypage2 article h3 {
    /* padding-left: 20px; */
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'NotoSansKRMedium sans-serif';
}

.mypage2 article .user-info {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 50px;
}

.mypage2 article .user-info .account-info {
    display: block;
    margin: 0;
}

.mypage2 article .user-info .account-info .text-field {
    width: 400px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.mypage2 article .user-info .account-info .text-field label {
    font-size: 14px;
    font-family: NotoSansKRMedium sans-serif;
}

.mypage2 article .user-info .account-info .text-field input {
    border: 0.5px solid #9d9d9d;
    padding: 9px 8px;
}

.mypage2 article .user-info .account-info div.set-pw {
    color: #118ec7;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border: 0.5px solid #118ec7;
    width: 400px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    font-family: NotoSansKRMedium sans-serif;
    margin-top: 30px;
}

.mypage3 article .ksd-account-info {
    margin-top: 0;
}

.mypage4 article .ksd-account-info {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.mypage4 article .ksd-account-info .form-wrap {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.mypage4 article .ksd-account-info .form-wrap button {
    font-size: 14px;
}

.mypage4 article .ksd-account-info h3 {
    margin: 0;
    margin-bottom: 20px;
}

.mypage4 article .ksd-account-info .search-mypage {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    margin-top: 50px;
    margin-bottom: 5px;
}

.mypage4 .mypage-group-li-selected {
    margin-right: 0;
}

.mypage4 .mypage-group-li-not-selected {
    margin-right: 0;
}

.mypage4 .mypage-group-input-not-selected, .mypage4 .mypage-group-input-selected {
    width: 100%;
    height: 40px;
}

.mypage4 .fixed-table {
    margin: 0;
}


.popup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 65%);
}

.popup .password-popup {
    width: 800px;
    height: 500px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 0 50px;
}

.popup .password-popup h3 {
    font-size: 18px;
    /*font-weight: bold;*/
    margin-bottom: 60px;
    color: #323232;
    font-family: NotoSansKRMedium sans-serif;
}

.popup .password-popup .pw-reset-textfield {
    display: flex;
    padding: 0 100px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.popup .password-popup .pw-reset-textfield label {
    font-size: 16px;
    /*font-weight: bold;*/
    line-height: 35px;
}

.popup .password-popup .pw-reset-textfield input {
    width: 300px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #6e6e6e;
}

.popup .password-popup button {
    width: 200px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 5px;
    /*font-weight: bold;*/
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    font-size: 18px;
    border: 0;
    background-color: #18629e;
    font-family: NotoSansKRMedium sans-serif;
}

.message-list article .options,
.message-send article .options,
.message-detail article .options {
    display: flex;
    align-items: center;
    margin-top: 60px;
    gap: 30px;
    padding: 0 30px;
}

.message-send article .options {
    gap: 0;
}

.message-list article .options .select-option,
.confirm-list article .options .select-option,
.message-send article .options .select-option,
.message-detail article .options .select-option {
    align-items: center;
    display: flex;
    height: 50px;
}

.message-send article .options .select-option {
    margin-right: 30px;
}

.message-list article .options label,
.confirm-list article .options label,
.message-send article .options label,
.message-detail article .options label {
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 13px;
    font-family: NotoSansKRMedium sans-serif;
}

.message-list article .options .select-option select,
.confirm-list article .options .select-option select,
.message-send article .options .select-option select,
.message-detail article .options .select-option select {
    border-radius: 25px;
    width: 200px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 13px;
}

.message-list article .fixed-table th,
.confirm-list article .fixed-table th,
.message-send article .fixed-table th,
.message-detail article .fixed-table th {
    border: 0.5px solid #d8d8d8;
    background-color: #ffffff;
    z-index: 100;
    /*height: 30px;*/
    /*line-height: 20px;*/
    color: #000000;
    vertical-align: middle;
    /*padding: 6px 8px;*/
    font-size: 13px;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

.message-list footer, .confirm-list footer {
    padding: 0 30px;
}

.confirm-list article {
    /*padding: 0 30px;*/
    overflow: auto;
}

.confirm-list .action-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
}

.confirm-list .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #b0b0b0;
    margin-top: 50px;
}

.confirm-list .select-options {
    display: flex;
    align-items: center;
    gap: 20px;
}

.confirm-list .select-option {
    display: flex;
    height: 50px;
}

.message-send textarea,
.message-detail textarea {
    height: 140px;
    width: calc(100% - 60px);
    border-radius: 5px;
    border: none;
    padding: 20px;
    resize: none;
    background-color: #f6f6f6;
    margin-left: 30px;
    /* margin-right: 30px; */
    margin-top: 5px;
    margin-bottom: 30px;
}


.lend-pool-register table th:nth-child(1) {
    width: 60px;
}

.lend-pool-register table th:nth-child(2) {
    width: 100px;
}

.lend-pool-register table th:nth-child(3) {
    width: 200px;
}

.lend-pool-register table th:nth-child(4) {
    width: 120px;
}

.lend-pool-register table th:nth-child(5) {
    width: 120px;
}

.lend-pool-register table th:nth-child(6) {
    width: 140px;
}

.borrow-request-order.order-normal table th:nth-child(1) {
    width: 60px;
}

.borrow-request-order.order-normal table th:nth-child(2) {
    width: 100px;
}

.borrow-request-order.order-normal table th:nth-child(3) {
    width: 200px;
}

.borrow-request-order.order-normal table th:nth-child(4) {
    width: 120px;
}

.borrow-request-order.order-normal table th:nth-child(5) {
    width: 120px;
}

.borrow-request-order.order-normal table th:nth-child(6) {
    width: 140px;
}

.borrow-request-order.order-normal table th:nth-child(7) {
    width: 80px;
}

.borrow-request-order.order-normal table th:nth-child(8) {
    width: 60px;
}

.borrow-request-order .tap-LIVE table th:nth-child(1) {
    width: 60px;
}

.borrow-request-order .tap-LIVE table th:nth-child(2) {
    width: 100px;
}

.borrow-request-order .tap-LIVE table th:nth-child(3) {
    width: 200px;
}

.borrow-request-order .tap-LIVE table th:nth-child(4) {
    width: 120px;
}

.borrow-request-order .tap-LIVE table th:nth-child(5) {
    width: 120px;
}

.borrow-request-order .tap-LIVE table th:nth-child(6) {
    width: 140px;
}

.borrow-request-order .tap-LIVE table th:nth-child(7) {

}


.borrow-pool-list .tlist table th:nth-child(1) {
    width: 40px;
}

.borrow-pool-list .tlist table th:nth-child(2) {
    width: 50px;
}

.borrow-pool-list .tlist table th:nth-child(3) {
    width: 80px;
}

.borrow-pool-list .tlist table th:nth-child(4) {
    width: 100px;
}

.borrow-pool-list .tlist table th:nth-child(5) {
    width: 200px;
}

.borrow-pool-list .tlist table th:nth-child(6) {
    width: 120px;
}

.borrow-pool-list .tlist table th:nth-child(7) {
    width: 120px;
}

.borrow-pool-list .tlist table th:nth-child(8) {
    width: 160px;
}

.borrow-pool-list .tlist table th:nth-child(9) {
    width: 100px;
}

.borrow-pool-list .tlist table th:nth-child(10) {
    width: 120px;
}

.borrow-pool-list .tlist table th:nth-child(11) {
    width: 120px;
}

.borrow-pool-list .tlist table th:nth-child(12) {
    width: 80px;
}

.borrow-pool-list .live table th:nth-child(1) {
    width: 40px;
}

.borrow-pool-list .live table th:nth-child(2) {
    width: 50px;
}

.borrow-pool-list .live table th:nth-child(3) {
    width: 100px;
}

.borrow-pool-list .live table th:nth-child(4) {
    width: 200px;
}

.borrow-pool-list .live table th:nth-child(5) {
    width: 120px;
}

.borrow-pool-list .live table th:nth-child(6) {
    width: 120px;
}

.borrow-pool-list .live table th:nth-child(7) {
    width: 120px;
}


.borrow-request-list .tlist table th:nth-child(1) {
    width: 40px;
}

.borrow-request-list .tlist table th:nth-child(2) {
    width: 80px;
}

.borrow-request-list .tlist table th:nth-child(3) {
    width: 50px;
}

.borrow-request-list .tlist table th:nth-child(4) {
    width: 80px;
}

.borrow-request-list .tlist table th:nth-child(5) {
    width: 70px;
}

.borrow-request-list .tlist table th:nth-child(6) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(7) {
    width: 200px;
}

.borrow-request-list .tlist table th:nth-child(8) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(9) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(10) {
    width: 120px;
}

.borrow-request-list .tlist table th:nth-child(11) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(12) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(13) {
    width: 100px;
}

.borrow-request-list .tlist table th:nth-child(14) {
    width: 200px;
}

.borrow-request-list .live table th:nth-child(1) {
    width: 40px;
}

.borrow-request-list .live table th:nth-child(2) {
    width: 80px;
}

.borrow-request-list .live table th:nth-child(3) {
    width: 50px;
}

.borrow-request-list .live table th:nth-child(4) {
    width: 70px;
}

.borrow-request-list .live table th:nth-child(5) {
    width: 70px;
}

.borrow-request-list .live table th:nth-child(6) {
    width: 100px;
}

.borrow-request-list .live table th:nth-child(7) {
    width: 200px;
}

.borrow-request-list .live table th:nth-child(8) {
    width: 120px;
}

.borrow-request-list .live table th:nth-child(9) {
    width: 100px;
}

.borrow-request-list .live table th:nth-child(10) {
    width: 100px;
}

.borrow-request-list .live table th:nth-child(11) {
    width: 100px;
}

.lend-order-response table th:nth-child(1) {
    width: 40px;
}

.lend-order-response table th:nth-child(2) {
    width: 50px;
}

.lend-order-response table th:nth-child(3) {
    width: 100px;
}

.lend-order-response table th:nth-child(4) {
    width: 110px;
}

.lend-order-response table th:nth-child(5) {
    width: 80px;
}

.lend-order-response table th:nth-child(6) {
    width: 160px;
}

.lend-order-response table th:nth-child(7) {
    width: 70px;
}

.lend-order-response table th:nth-child(8) {
    width: 160px;
}

.lend-order-response table th:nth-child(9) {
    width: 90px;
}

.lend-order-response table th:nth-child(10) {
    width: 90px;
}

.lend-order-response table th:nth-child(11) {
    width: 90px;
}

.lend-order-response table th:nth-child(12) {
    width: 90px;
}

.lend-order-response table th:nth-child(13) {
    width: 90px;
}

.lend-order-response table th:nth-child(14) {
    width: 90px;
}

.lend-order-response table th:nth-child(15) {
    width: 90px;
}

.lend-order-response table th:nth-child(16) {
    width: 100px;
}

.lend-order-response table th:nth-child(17) {
    width: 100px;
}

.borrow-order-response table th:nth-child(1){
    width: 40px;
}

.borrow-order-response table th:nth-child(2){
    width: 50px;
}

.borrow-order-response table th:nth-child(3){
    width: 100px;
}

.borrow-order-response table th:nth-child(4){
    width: 110px;
}

.borrow-order-response table th:nth-child(5){
    width: 80px;
}

.borrow-order-response table th:nth-child(6){
    width: 160px;
}

.borrow-order-response table th:nth-child(7){
    width: 70px;
}

.borrow-order-response table th:nth-child(8){
    width: 160px;
}

.borrow-order-response table th:nth-child(9){
    width: 90px;
}

.borrow-order-response table th:nth-child(10){
    width: 90px;
}

.borrow-order-response table th:nth-child(11){
    width: 90px;
}

.borrow-order-response table th:nth-child(12){
    width: 90px;
}

.borrow-order-response table th:nth-child(13){
    width: 90px;
}

.borrow-order-response table th:nth-child(14){
    width: 90px;
}

.borrow-order-response table th:nth-child(15){
    width: 90px;
}

.borrow-order-response table th:nth-child(16){
    width: 100px;
}

.borrow-order-response table th:nth-child(17){

}


.borrow-order-list table th:nth-child(1) {
    width: 60px;
}

.borrow-order-list table th:nth-child(2) {
    width: 60px;
}

.borrow-order-list table th:nth-child(3) {
    width: 80px;
}

.borrow-order-list table th:nth-child(4) {
    width: 80px;
}

.borrow-order-list table th:nth-child(5) {
    width: 60px;
}

.borrow-order-list table th:nth-child(6) {
    width: 80px;
}

.borrow-order-list table th:nth-child(7) {
    width: 200px;
}

.borrow-order-list table th:nth-child(8) {
    width: 100px;
}

.borrow-order-list table th:nth-child(9) {
    width: 200px;
}

.borrow-order-list table th:nth-child(10) {
    width: 120px;
}

.borrow-order-list table th:nth-child(11) {
    width: 120px;
}

.borrow-order-list table th:nth-child(12) {
    width: 120px;
}

.borrow-order-list table th:nth-child(13) {
    width: 120px;
}

.borrow-order-list table th:nth-child(14) {
    width: 120px;
}

.confirm-list table th:nth-child(1) {
    width: 60px;
}

.confirm-list table th:nth-child(2) {
    width: 60px;
}

.confirm-list table th:nth-child(3) {
    width: 60px;
}

.confirm-list table th:nth-child(4) {
    width: 100px;
}

.confirm-list table th:nth-child(5) {
    width: 100px;
}

.confirm-list table th:nth-child(6) {
    width: 100px;
}

.confirm-list table th:nth-child(7) {
    width: 200px;
}

.confirm-list table th:nth-child(8) {
    width: 100px;
}

.confirm-list table th:nth-child(9) {
    width: 200px;
}

.confirm-list table th:nth-child(10) {
    width: 120px;
}

.confirm-list table th:nth-child(11) {
    width: 120px;
}

.confirm-list table th:nth-child(12) {
    width: 120px;
}

.confirm-list table th:nth-child(13) {
    width: 120px;
}

.message-send table th:nth-child(1) {
    width: 60px;
}

.message-send table th:nth-child(2) {
    width: 60px;
}

.message-send table th:nth-child(3) {
    width: 100px;
}

.message-send table th:nth-child(4) {
    width: 100px;
}

.message-send table th:nth-child(5) {
    width: 60px;
}

.message-send table th:nth-child(6) {
    width: 200px;
}

.message-send table th:nth-child(7) {
    width: 100px;
}

.message-send table th:nth-child(8) {
    width: 200px;
}

.message-send table th:nth-child(9) {
    width: 120px;
}

.message-send table th:nth-child(10) {
    width: 120px;
}

.message-send table th:nth-child(11) {
    width: 120px;
}

.lend-pool-register-list .tlist table th:nth-child(1) {
    width: 40px;
}

.lend-pool-register-list .tlist table th:nth-child(2) {
    width: 80px;
}

.lend-pool-register-list .tlist table th:nth-child(3) {
    width: 50px;
}

.lend-pool-register-list .tlist table th:nth-child(4) {
    width: 80px;
}

.lend-pool-register-list .tlist table th:nth-child(5) {
    width: 70px;
}

.lend-pool-register-list .tlist table th:nth-child(6) {
    width: 100px;
}

.lend-pool-register-list .tlist table th:nth-child(7) {
    width: 200px;
}

.lend-pool-register-list .tlist table th:nth-child(8) {
    width: 120px;
}

.lend-pool-register-list .tlist table th:nth-child(9) {
    width: 120px;
}

.lend-pool-register-list .tlist table th:nth-child(10) {
    width: 130px;
}

.lend-pool-register-list .tlist table th:nth-child(11) {
    width: 120px;
}

.lend-pool-register-list .tlist table th:nth-child(12) {
    width: 120px;
}

.lend-pool-register-list .tlist table th:nth-child(13) {
    width: 300px;
}

.lend-pool-register-list .live table th:nth-child(1) {
    width: 40px;
}

.lend-pool-register-list .live table th:nth-child(2) {
    width: 80px;
}

.lend-pool-register-list .live table th:nth-child(3) {
    width: 50px;
}

.lend-pool-register-list .live table th:nth-child(4) {
    width: 80px;
}

.lend-pool-register-list .live table th:nth-child(5) {
    width: 70px;
}

.lend-pool-register-list .live table th:nth-child(6) {
    width: 100px;
}

.lend-pool-register-list .live table th:nth-child(7) {
    width: 200px;
}

.lend-pool-register-list .live table th:nth-child(8) {
    width: 120px;
}

.lend-pool-register-list .live table th:nth-child(9) {
    width: 120px;
}

.lend-pool-register-list .live table th:nth-child(10) {
    width: 120px;
}

.lend-pool-register-list .live table th:nth-child(11) {
    width: 120px;
}

.lend-pool-matched .tlist table th:nth-child(1) {
    width: 80px;
}

.lend-pool-matched .tlist table th:nth-child(2) {
    width: 50px;
}

.lend-pool-matched .tlist table th:nth-child(3) {
    width: 80px;
}

.lend-pool-matched .tlist table th:nth-child(4) {
    width: 100px;
}

.lend-pool-matched .tlist table th:nth-child(5) {
    width: 200px;
}

.lend-pool-matched .tlist table th:nth-child(6) {
    width: 120px;
}

.lend-pool-matched .tlist table th:nth-child(7) {
    width: 120px;
}

.lend-pool-matched .tlist table th:nth-child(8) {
    width: 120px;
}

.lend-pool-matched .tlist table th:nth-child(9) {
    width: 120px;
}

.lend-pool-matched .tlist table th:nth-child(10) {
    width: 120px;
}

.lend-pool-matched .tlist table th:nth-child(11) {
}

.lend-pool-matched .tab-LIVE table th:nth-child(1) {
    width: 80px;
}

.lend-pool-matched .tab-LIVE table th:nth-child(2) {
    width: 50px;
}

.lend-pool-matched .tab-LIVE table th:nth-child(3) {
    width: 100px;
}

.lend-pool-matched .tab-LIVE table th:nth-child(4) {
    width: 200px;
}

.lend-pool-matched .tab-LIVE table th:nth-child(5) {
    width: 120px;
}

.lend-pool-matched .tab-LIVE table th:nth-child(6) {
    width: 120px;
}

.lend-pool-matched .tab-LIVE table th:nth-child(7) {
    width: 120px;
}


.lend-borrow-list .tlist table th:nth-child(1) {
    width: 40px;
}

.lend-borrow-list .tlist table th:nth-child(2) {
    width: 50px;
}

.lend-borrow-list .tlist table th:nth-child(3) {
    width: 80px;
}

.lend-borrow-list .tlist table th:nth-child(4) {
    width: 100px;
}

.lend-borrow-list .tlist table th:nth-child(5) {
    width: 200px;
}

.lend-borrow-list .tlist table th:nth-child(6) {
    width: 120px;
}

.lend-borrow-list .tlist table th:nth-child(7) {
    width: 120px;
}

.lend-borrow-list .tlist table th:nth-child(8) {
    width: 160px;
}

.lend-borrow-list .tlist table th:nth-child(9) {
    width: 110px;
}

.lend-borrow-list .tlist table th:nth-child(10) {
    width: 120px;
}

.lend-borrow-list .tlist table th:nth-child(11) {
    width: 120px;
}

.lend-borrow-list .tlist table th:nth-child(12) {
    width: 140px;
}

.lend-borrow-list .tlist table th:nth-child(13) {
    width: 100px;
}

.lend-search .tab-LEDGER table th:nth-child(1),
.borrow-search .tab-LEDGER table th:nth-child(1) {
    width: 40px;
}

.lend-search .tab-LEDGER table th:nth-child(2),
.borrow-search .tab-LEDGER table th:nth-child(2) {
    width: 50px;
}

.lend-search .tab-LEDGER table th:nth-child(3),
.borrow-search .tab-LEDGER table th:nth-child(3) {
    width: 100px;
}

.lend-search .tab-LEDGER table th:nth-child(4),
.borrow-search .tab-LEDGER table th:nth-child(4) {
    width: 110px;
}

.lend-search .tab-LEDGER table th:nth-child(5),
.borrow-search .tab-LEDGER table th:nth-child(5) {
    width: 80px;
}

.lend-search .tab-LEDGER table th:nth-child(6),
.borrow-search .tab-LEDGER table th:nth-child(6) {
    width: 120px;
}

.lend-search .tab-LEDGER table th:nth-child(7),
.borrow-search .tab-LEDGER table th:nth-child(7) {
    width: 120px;
}

.lend-search .tab-LEDGER table th:nth-child(8),
.borrow-search .tab-LEDGER table th:nth-child(8) {
    width: 80px;
}

.lend-search .tab-LEDGER table th:nth-child(9),
.borrow-search .tab-LEDGER table th:nth-child(9) {
    width: 180px;
}

.lend-search .tab-LEDGER table th:nth-child(10),
.borrow-search .tab-LEDGER table th:nth-child(10) {
    width: 80px;
}

.lend-search .tab-LEDGER table th:nth-child(11),
.borrow-search .tab-LEDGER table th:nth-child(11) {
    width: 160px;
}

.lend-search .tab-LEDGER table th:nth-child(12),
.borrow-search .tab-LEDGER table th:nth-child(12) {
    width: 100px;
}

.lend-search .tab-LEDGER table th:nth-child(13),
.borrow-search .tab-LEDGER table th:nth-child(13) {
    width: 100px;
}

.lend-search .tab-LEDGER table th:nth-child(14),
.borrow-search .tab-LEDGER table th:nth-child(14) {
    width: 100px;
}

.lend-search .tab-LEDGER table th:nth-child(15),
.borrow-search .tab-LEDGER table th:nth-child(15) {
}

.lend-search .tab-RESPONSE table th:nth-child(1),
.borrow-search .tab-RESPONSE table th:nth-child(1) {
    width: 40px;
}

.lend-search .tab-RESPONSE table th:nth-child(2),
.borrow-search .tab-RESPONSE table th:nth-child(2) {
    width: 50px;
}

.lend-search .tab-RESPONSE table th:nth-child(3),
.borrow-search .tab-RESPONSE table th:nth-child(3) {
    width: 100px;
}

.lend-search .tab-RESPONSE table th:nth-child(4),
.borrow-search .tab-RESPONSE table th:nth-child(4) {
    width: 110px;
}

.lend-search .tab-RESPONSE table th:nth-child(5),
.borrow-search .tab-RESPONSE table th:nth-child(5) {
    width: 100px;
}

.lend-search .tab-RESPONSE table th:nth-child(6),
.borrow-search .tab-RESPONSE table th:nth-child(6) {
    width: 80px;
}

.lend-search .tab-RESPONSE table th:nth-child(7),
.borrow-search .tab-RESPONSE table th:nth-child(7) {
    width: 200px;
}

.lend-search .tab-RESPONSE table th:nth-child(8),
.borrow-search .tab-RESPONSE table th:nth-child(8) {
    width: 80px;
}

.lend-search .tab-RESPONSE table th:nth-child(9),
.borrow-search .tab-RESPONSE table th:nth-child(9) {
    width: 160px;
}

.lend-search .tab-RESPONSE table th:nth-child(10),
.borrow-search .tab-RESPONSE table th:nth-child(10) {
    width: 100px;
}

.lend-search .tab-RESPONSE table th:nth-child(11),
.borrow-search .tab-RESPONSE table th:nth-child(11) {
    width: 100px;
}

.lend-search .tab-RESPONSE table th:nth-child(12),
.borrow-search .tab-RESPONSE table th:nth-child(12) {
    width: 120px;
}

.lend-search .tab-RESPONSE table th:nth-child(13),
.borrow-search .tab-RESPONSE table th:nth-child(13) {
    width: 100px;
}

.lend-search .tab-HISTORY table th:nth-child(1),
.borrow-search .tab-HISTORY table th:nth-child(1) {
    width: 50px;
}

.lend-search .tab-HISTORY table th:nth-child(2),
.borrow-search .tab-HISTORY table th:nth-child(2) {
    width: 80px;
}

.lend-search .tab-HISTORY table th:nth-child(3),
.borrow-search .tab-HISTORY table th:nth-child(3) {
    width: 80px;
}

.lend-search .tab-HISTORY table th:nth-child(4),
.borrow-search .tab-HISTORY table th:nth-child(4) {
    width: 80px;
}

.lend-search .tab-HISTORY table th:nth-child(5),
.borrow-search .tab-HISTORY table th:nth-child(5) {
    width: 120px;
}

.lend-search .tab-HISTORY table th:nth-child(6),
.borrow-search .tab-HISTORY table th:nth-child(6) {
    width: 80px;
}

.lend-search .tab-HISTORY table th:nth-child(7),
.borrow-search .tab-HISTORY table th:nth-child(7) {
    width: 200px;
}

.lend-search .tab-HISTORY table th:nth-child(8),
.borrow-search .tab-HISTORY table th:nth-child(8) {
    width: 200px;
}

.lend-search .tab-HISTORY table th:nth-child(9),
.borrow-search .tab-HISTORY table th:nth-child(9) {
    width: 80px;
}

.lend-search .tab-HISTORY table th:nth-child(10),
.borrow-search .tab-HISTORY table th:nth-child(10) {
    width: 160px;
}

.lend-search .tab-HISTORY table th:nth-child(11),
.borrow-search .tab-HISTORY table th:nth-child(11) {
    width: 100px;
}

.lend-search .tab-HISTORY table th:nth-child(12),
.borrow-search .tab-HISTORY table th:nth-child(12) {
    width: 100px;
}

.lend-search .tab-HISTORY table th:nth-child(13),
.borrow-search .tab-HISTORY table th:nth-child(13) {
    width: 100px;
}

.lend-search .tab-HISTORY table th:nth-child(14),
.borrow-search .tab-HISTORY table th:nth-child(14) {
    width: 100px;
}

.lend-borrow-list .live table th:nth-child(1) {
    width: 40px;
}

.lend-borrow-list .live table th:nth-child(2) {
    width: 50px;
}

.lend-borrow-list .live table th:nth-child(3) {
    width: 100px;
}

.lend-borrow-list .live table th:nth-child(4) {
    width: 200px;
}

.lend-borrow-list .live table th:nth-child(5) {
    width: 120px;
}

.lend-borrow-list .live table th:nth-child(6) {
    width: 120px;
}

.lend-borrow-list .live table th:nth-child(7) {
    width: 120px;
}

.lend-order-list.nego-requester table th:nth-child(1),
.borrow-order-list.nego-requester table th:nth-child(1) {
    width: 50px;
}

.lend-order-list.nego-requester table th:nth-child(2),
.borrow-order-list.nego-requester table th:nth-child(2) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(3),
.borrow-order-list.nego-requester table th:nth-child(3) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(4),
.borrow-order-list.nego-requester table th:nth-child(4) {
    width: 80px;
}

.lend-order-list.nego-requester table th:nth-child(5),
.borrow-order-list.nego-requester table th:nth-child(5) {
    width: 130px;
}

.lend-order-list.nego-requester table th:nth-child(6),
.borrow-order-list.nego-requester table th:nth-child(6) {
    width: 180px;
}

.lend-order-list.nego-requester table th:nth-child(7),
.borrow-order-list.nego-requester table th:nth-child(7) {
    width: 80px;
}

.lend-order-list.nego-requester table th:nth-child(8),
.borrow-order-list.nego-requester table th:nth-child(8) {
    width: 160px;
}

.lend-order-list.nego-requester table th:nth-child(9),
.borrow-order-list.nego-requester table th:nth-child(9) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(10),
.borrow-order-list.nego-requester table th:nth-child(10) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(11),
.borrow-order-list.nego-requester table th:nth-child(11) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(12),
.borrow-order-list.nego-requester table th:nth-child(12) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(13),
.borrow-order-list.nego-requester table th:nth-child(13) {
    width: 100px;
}

.lend-order-list.nego-requester table th:nth-child(14),
.borrow-order-list.nego-requester table th:nth-child(14) {
}

.lend-order-list.nego-requester-cancel table th:nth-child(1),
.borrow-order-list.nego-requester-cancel table th:nth-child(1) {
    width: 40px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(2),
.borrow-order-list.nego-requester-cancel table th:nth-child(2) {
    width: 50px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(3),
.borrow-order-list.nego-requester-cancel table th:nth-child(3) {
    width: 100px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(4),
.borrow-order-list.nego-requester-cancel table th:nth-child(4) {
    width: 110px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(5),
.borrow-order-list.nego-requester-cancel table th:nth-child(5) {
    width: 80px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(6),
.borrow-order-list.nego-requester-cancel table th:nth-child(6) {
    width: 130px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(7),
.borrow-order-list.nego-requester-cancel table th:nth-child(7) {
    width: 180px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(8),
.borrow-order-list.nego-requester-cancel table th:nth-child(8) {
    width: 80px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(9),
.borrow-order-list.nego-requester-cancel table th:nth-child(9) {
    width: 160px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(10),
.borrow-order-list.nego-requester-cancel table th:nth-child(10) {
    width: 100px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(11),
.borrow-order-list.nego-requester-cancel table th:nth-child(11) {
    width: 100px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(12),
.borrow-order-list.nego-requester-cancel table th:nth-child(12) {
    width: 100px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(13),
.borrow-order-list.nego-requester-cancel table th:nth-child(13) {
    width: 100px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(14),
.borrow-order-list.nego-requester-cancel table th:nth-child(14) {
    width: 100px;
}

.lend-order-list.nego-requester-cancel table th:nth-child(15),
.borrow-order-list.nego-requester-cancel table th:nth-child(16) {

}

.lend-order-list.nego-not-requester table th:nth-child(1),
.borrow-order-list.nego-not-requester table th:nth-child(1) {
    width: 50px;
}

.lend-order-list.nego-not-requester table th:nth-child(2),
.borrow-order-list.nego-not-requester table th:nth-child(2) {
    width: 80px;
}

.lend-order-list.nego-not-requester table th:nth-child(3),
.borrow-order-list.nego-not-requester table th:nth-child(3) {
    width: 80px;
}

.lend-order-list.nego-not-requester table th:nth-child(4),
.borrow-order-list.nego-not-requester table th:nth-child(4) {
    width: 80px;
}

.lend-order-list.nego-not-requester table th:nth-child(5),
.borrow-order-list.nego-not-requester table th:nth-child(5) {
    width: 130px;
}

.lend-order-list.nego-not-requester table th:nth-child(6),
.borrow-order-list.nego-not-requester table th:nth-child(6) {
    width: 180px;
}

.lend-order-list.nego-not-requester table th:nth-child(7),
.borrow-order-list.nego-not-requester table th:nth-child(7) {
    width: 80px;
}

.lend-order-list.nego-not-requester table th:nth-child(8),
.borrow-order-list.nego-not-requester table th:nth-child(8) {
    width: 160px;
}

.lend-order-list.nego-not-requester table th:nth-child(9),
.borrow-order-list.nego-not-requester table th:nth-child(9) {
    width: 110px;
}

.lend-order-list.nego-not-requester table th:nth-child(10),
.borrow-order-list.nego-not-requester table th:nth-child(10) {
    width: 110px;
}

.lend-order-list.nego-not-requester table th:nth-child(11),
.borrow-order-list.nego-not-requester table th:nth-child(11) {
    width: 110px;
}

.lend-order-list.nego-not-requester table th:nth-child(12),
.borrow-order-list.nego-not-requester table th:nth-child(12) {
    width: 110px;
}

.lend-order-list.nego-not-requester table th:nth-child(13),
.borrow-order-list.nego-not-requester table th:nth-child(13) {
    width: 110px;
}

.fixed-table-confirm th:nth-child(1) {
    width: 40px;
}

.fixed-table-confirm th:nth-child(2) {
    width: 60px;
}

.fixed-table-confirm th:nth-child(3) {
    width: 100px;
}

.fixed-table-confirm th:nth-child(4) {
    width: 110px;
}

.fixed-table-confirm th:nth-child(5) {
    width: 100px;
}

.fixed-table-confirm th:nth-child(6) {
    width: 80px;
}

.fixed-table-confirm th:nth-child(7) {
    width: 200px;
}

.fixed-table-confirm th:nth-child(8) {
    width: 80px;
}

.fixed-table-confirm th:nth-child(9) {
    width: 160px;
}

.fixed-table-confirm th:nth-child(10) {
    width: 100px;
}

.fixed-table-confirm th:nth-child(11) {
    width: 100px;
}

.fixed-table-confirm th:nth-child(12) {
    width: 100px;
}

.fixed-table-confirm th:nth-child(13) {
}

.message-list table th:nth-child(1) {
    width: 60px;
}

.message-list table th:nth-child(2) {
    width: 100px;
}

.message-list table th:nth-child(3) {
    width: 300px;
}

.message-list table th:nth-child(4) {
    width: 100px;
}

.message-list table th:nth-child(5) {
    width: 100px;
}

.message-list table th:nth-child(6) {
    width: 60px;
}

.message-list table th:nth-child(7) {
    width: 100px;
}

.message-list table th:nth-child(8) {
    width: 80px;
}

.message-list table th:nth-child(9) {
}

.message-detail table th:nth-child(1) {
    width: 60px;
}

.message-detail table th:nth-child(2) {
    width: 80px;
}

.message-detail table th:nth-child(3) {
    width: 80px;
}

.message-detail table th:nth-child(4) {
    width: 60px;
}

.message-detail table th:nth-child(5) {
    width: 200px;
}

.message-detail table th:nth-child(6) {
    width: 100px;
}

.message-detail table th:nth-child(7) {
    width: 200px;
}

.message-detail table th:nth-child(8) {
    width: 120px;
}

.message-detail table th:nth-child(9) {
    width: 120px;
}

.message-detail table th:nth-child(10) {
    width: 140px;
}

.message-send table th:nth-child(1) {
    width: 40px;
}

.message-send table th:nth-child(2) {
    width: 100px;
}

.message-send table th:nth-child(3) {
    width: 120px;
}

.message-send table th:nth-child(4) {
    width: 120px;
}

.message-send table th:nth-child(5) {
    width: 60px;
}

.message-send table th:nth-child(6) {
    width: 240px;
}

.message-send table th:nth-child(7) {
    width: 100px;
}

.message-send table th:nth-child(8) {
    width: 160px;
}

.message-send table th:nth-child(9) {
    width: 120px;
}

.message-send table th:nth-child(10) {
    width: 120px;
}

.message-send table th:nth-child(11) {
    width: 120px;
}

.message-send table th:nth-child(12) {
}


.ksd-account-info .registered-account table th:nth-child(1) {
    width: 50px;
}

.ksd-account-info .registered-account table th:nth-child(2) {
    width: 40px;
}

.ksd-account-info .registered-account table th:nth-child(3) {
    width: 160px;
}

.ksd-account-info .registered-account table th:nth-child(4) {
    width: 120px;
}

.ksd-account-info .registered-account table th:nth-child(5) {
    width: 200px;
}

.ksd-account-info .registered-account table th:nth-child(6) {
    width: 400px;
}

.ksd-account-info .registered-account table th:nth-child(7) {
    width: 100px;
}

.ksd-account-info .saved-list table th:nth-child(1) {
    width: 200px;
}

.ksd-account-info .saved-list table th:nth-child(2) {
    width: 140px;
}

.ksd-account-info .saved-list table th:nth-child(3) {
    width: 120px;
}

.ksd-account-info .saved-list table th:nth-child(4) {
    width: 50px;
}

.fixed-table th button {
    font-size: 13px;
    align-items: center;
    /* justify-content: left; */
    padding: 0;
    min-width: auto;
    /*position: absolute;*/
    /*top: 48%;*/
    /*transform: translateY(-50%);*/
}

.message-list .fixed-table th button {
    font-size: 13px;
    justify-content: left;
    padding: 0;
    min-width: auto;
    color: #000000;
}

.fixed-table th button svg {
    font-size: 1.0rem;
}

.LEND .lend-th-change {
    background-color: rgb(0 87 172) !important;
}

.BORROW .lend-th-change {
    background-color: rgb(204 43 1) !important;
}

.th-change {
    /*background-color:  #accb8a !important;*/
}

/* .table-cell-filterable {
    background-color: #fffce1;
} */

.mypage-group-li-not-selected {
    list-style: none;
    margin-right: 80px;
    position: relative;
    margin-bottom: 10px;
}

.mypage-group-li-selected {
    list-style: none;
    margin-right: 80px;
    position: relative;
    margin-bottom: 10px;
}

.mypage-group-li-not-selected :nth-child(2),
.mypage-group-li-selected :nth-child(2) {
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.mypage-group-li-not-selected :nth-child(3),
.mypage-group-li-selected :nth-child(3) {
    right: -30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.mypage-group-input-not-selected,
.mypage-group-input-selected {
    width: 220px;
    height: 30px;
    position: relative;
    padding-left: 8px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    border: .5px solid #d3d3d3;
}

.mypage-group-input-selected {
    border: 2px solid #1976d2;
}

#parent-modal-title {
    font-size: 18px;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}

#parent-modal-description {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
}

#help-link {
    font-size: 16px;
    background-color: #118ec7;
    position: absolute;
    top: 85%;
    transform: translate(-50%, -50%);
    left: 50%;
    /*border: .5px solid #d3d3d3;*/
    padding: 14px 50px;
    border-radius: 3px;
    cursor: pointer;
}

#help-link:hover {
    background-color: rgba(17, 142, 199, 0.9);
}


#help-link a {
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
}


.is-mypage-true {
    /* background-color: #1976d2 !important; */
    /* color: yellow !important; */
}

.is-message-true {
    /* background-color: #1976d2 !important; */
}

.sidebar-top-svg {
    width: 16px;
    fill: #b9b9b9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sidebar-top-svg-selected {
    fill: #4e9700;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sidebar-top-text {
    color: #b9b9b9;
}

.sidebar-top-text-selected {
    color: #4e9700;
}

.sidebar-hr-borrow {
    /*margin-top: 47px;*/
    /*border: .5px solid #bdbdbd !important;*/
}

.sidebar-hr-lend {
    /* margin-top: 47px; */
    /*border: .5px solid #bdbdbd !important;*/
}

.mypage-not-read {
    margin-left: 30px;
}

.en-kr {
    margin: 20px 0;
}

.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    /*padding: 0 !important;*/
    /*font-size: 14px;*/
}

.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    /*padding: 0 8px !important;*/
}

.MuiAutocomplete-input {
    /*padding: 8px 0 !important;*/
    font-size: 14px;
}

.search-mypage fieldset {
    /*height: 40px;*/
    top: -10px;
}

.css-1e5fnj7-MuiAutocomplete-root {

}

#search-mypage-autocomplete-listbox {
    border: 0.5px solid #dbdbdb;
}

#search-mypage-autocomplete-listbox li {
    font-size: 14px;
    line-height: 18px;
}

.MuiAutocomplete-popper {
    z-index: 200000 !important;
}

.container-second {
    padding: 0;
    width: 100%;
}

.container-main {
    padding: 10px;
    flex: 1;
    /*position: relative;*/
}

.appbar {
    background: #191919;
    width: 100%;
}

.appbar .top-appbar .top-icon-wrap {
    width: 34px;
    height: 34px;
    background-color: #191919;
    border-radius: 20px;
    position: relative;
}

.top-appbar {
    width: 100%;
    height: 80px;
    position: relative;
}

.bot-appbar {
    width: 100%;
    height: 50px;
    position: relative;
    background-color: #262626;
}

.appbar .ul-wrap {
    display: flex;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
}

.appbar .top-appbar .ul-wrap {
    /*right: 200px;*/
    /*left: auto;*/
    /*background-color: #323232;*/
    padding: 8px 0;
    border-radius: 30px;
}

.appbar ul {
    display: flex;
    margin: 0 9px;
    background-color: #1a1a1a;
    padding: 0px 10px;
    border-radius: 30px;
}

.appbar > ul:nth-child(1) {
    left: 10px;
}

.appbar > ul:nth-child(2) {
    left: 400px;
}

.appbar > ul:nth-child(3) {
    left: 800px;
}

.ul-title-wrap {
    /*width: 50px;*/
    height: 30px;
    position: relative;
    margin-right: 10px;
}

.ul-title {
    height: 24px;
    line-height: 24px;
    /*width: 50px;*/
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*position: absolute;*/
    font-family: NotoSansKRMedium sans-serif;
    border-radius: 3px;
}

.ul-title-lend {
    color: #118ec7;
}

.ul-title-borrow {
    color: #d44343;
}

.ul-title-message {
    color: #4e9700;
}

.appbar ul li {
    /*color: #ffffff;*/
    display: flex;
    margin-right: 10px;
    padding: 0 8px;
    color: #b9b9b9;
    height: 24px;
    margin-top: 3px;
    background-color: #000000;
    /*border-radius: 3px;*/
}

.appbar ul li div {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
}

.menu-count {
    margin-left: 8px;
    /*font-weight: bold;*/
    font-family: NotoSansKRMedium sans-serif;
    color: #ffffff;
}

.MuiButton-root {
    font-family: NotoSansKRMedium sans-serif !important;
    font-weight: normal !important;
    /*font-weight: 100 !important;;*/
}

.message-autocomplete-li {
    font-size: 13px;
    padding: 4px 10px;
}

.message-autocomplete-li:hover {
    cursor: pointer;
    background-color: #c5c9ce;
}

.message-autocomplete-input {
    border-color: #000000;
}

.pool-register-list-modal {
    border: solid 1px #b0b0b0;
    /* min-width: 10px; */
    max-width: 400px;
    /* min-height: 38px; */
    background-color: #ffffff;
    z-index: 2;
    position: absolute;
    padding: 10px 20px;
    font-size: 13px;
    word-break: keep-all;
    line-height: 24px;
}

.input-text-align-right {
    text-align: right !important;
    width: 100%;
    box-shadow: inset 0 1px 3px 0 rgb(171 171 171 / 50%);
    border: none;
}

.text-color-blue {
    color: #1976d2 !important;
}

.pool-input-tost-modal {
    position: fixed;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    top: 500px;
    left: 46%;
    z-index: 2;
    background-color: #ffffff;
    border: solid #000000 1px;
    font-size: 14px;
    display: block;
}

.pool-input-tost-modal-none {
    position: fixed;
    top: 45%;
    left: 45%;
    display: none;
}

.swap-highlight-cell {
    background-color: #ddf6ff !important;
}

.rate-highlight-cell {
    background-color: #f5ffdd !important;
}

.notice-wrap {
    background-color: rgb(25 25 25 / 95%);
    padding: 24px 20px;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 460px;
    z-index: 2;
    color: #dadada;
    min-height: 160px;
    font-size: 16px;
}

.notice-wrap-none {
    display: none;
}


.notice-over-amount {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    display: block;
    text-align: left;
}

.notice-over-amount-none {
    display: none;
}

.confirm-modal-wrap {
    background-color: rgb(25 25 25 / 95%);
    padding: 24px 20px;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 460px;
    z-index: 2;
    color: #dadada;
    min-height: 160px;
    font-size: 16px;
}

.confirm-modal-btn {
    position: absolute;
    right: 24px;
    bottom: 20px;
}

.confirm-modal-btn button {
    background: unset;
    box-shadow: unset;
    border: 0;
    color: #1a76d2;
    font-size: 16px;
    line-height: 30px;
    height: 30px;
    width: 60px;
    text-align: center;
    font-family: NotoSansKRMedium sans-serif;
    cursor: pointer;
}

.confirm-modal-btn button:focus {
    outline: -webkit-focus-ring-color auto 1px;
}


.confirm-modal-btn button:nth-child(2) {
    color: #d44343;
}

.display-none {
    display: none;
}

.blink-text-blue {
    color: #b9b9b9;
    font-weight: bold;
    font-size: 2rem;
    animation: blinkingTextBlue .7s infinite;
}

.blink-text-red {
    color: #b9b9b9;
    font-weight: bold;
    font-size: 2rem;
    animation: blinkingTextRed .7s infinite;
}


.blink-text-green {
    color: #b9b9b9;
    font-weight: bold;
    font-size: 2rem;
    animation: blinkingTextGreen .7s infinite;
}

@keyframes blinkingTextBlue {
    0% {
        color: #b7ddee;
    }
    50% {
        color: #118ec7;
    }
    100% {
        color: #b7ddee;
    }
}

@keyframes blinkingTextRed {
    0% {
        color: #fddcdc;
    }
    50% {
        color: #d44343;
    }
    100% {
        color: #fddcdc;
    }
}

@keyframes blinkingTextGreen {
    0% {
        color: #ddf3d4;
    }
    50% {
        color: #4e9700;
    }
    100% {
        color: #ddf3d4;
    }
}

.blink-bg {
    color: #fff;
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    animation: blinkingBackground 2s infinite;
}

@keyframes blinkingBackground {
    0% {
        background-color: #10c018;
    }
    25% {
        background-color: #1056c0;
    }
    50% {
        background-color: #ef0a1a;
    }
    75% {
        background-color: #254878;
    }
    100% {
        background-color: #04a1d5;
    }
}

.li-group.Mui-focused {
    background: #e2e2e2;
}

.li-group-list.Mui-focused {
    background: #e2e2e2;
}

@keyframes blink {
    50% {
        border-color: #0000;
    }
}